import React from 'react'


const Navigation = () => {
  
    return (
    <div>
        <nav>
            <ul>
                <li>Home</li>
                <li>Mail</li>
                <li>Docuemts</li>
                <li>Settings</li>  
            </ul>
        </nav>
    </div>
  )
}

export default Navigation
import React from 'react';
import styled from 'styled-components';
import avatar from '../img/alexander-hipp-iEEBWgY_6lA-unsplash.png'
import { useContext } from 'react';
import { Context } from '../App';
const UserAvatarContainer = styled.div`
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.size};
    height: ${props => props.size};
    border-radius: 100%;
    overflow: hidden;
    
    img{
        width: 100%;
        height: 100%;
        
    }
`;

const UserAvatar = (props) => {

    const {user} = useContext(Context)
    const avatarUrl = user.userProfilePics.small
    return (
        <UserAvatarContainer size={`${props.size}px` || '30px'}>
            <img src={avatarUrl} alt=""/>
        </UserAvatarContainer>
    );
};

export default UserAvatar;
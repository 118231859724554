import React, { createContext, useContext, useState } from 'react'
import styled from 'styled-components'
import MessageComposer from './MessageComposer'
import axiosInstance from '../api/axiosInstance';
import { useEffect } from 'react';
import { Context } from '../App';
import Attachments from './Attachments';
import { Button } from '../ui';

import EmailConversationsHeader from './EmailConversationsHeader';
import EmailConversationsSearch from './EmailConversationsSearch';
import NavConversation from './NavConversation';



const Container = styled.div`

    display: flex;
    height: 100%;
`

export const MailContext = createContext()


const MailBoxSelectorContainer = styled.div`
    
    display: flex;
    gap: 10px;
`;

const MailboxButton = styled.button`

    border: 1px solid ${props => props.selected ? '#ccc' : 'transparent'};
    background-color: ${props => props.selected ? '#ccc' : 'transparent'};

`;
const MailBoxSelector = (props) => {

    const { selectedMailbox, setSelectedMailbox, setIsNewMailCompose } = useContext(MailContext)

    return (
        <MailBoxSelectorContainer>

        </MailBoxSelectorContainer>
    )
}


const MailNavContainer = styled.div`

   
    border-right: 1.4px solid #BABABA;
    height: 100%;
    width: 310px;
   
`;


const HeaderSearchContainer = styled.div`

    display: flex;
    flex-flow: column;
    padding: 0px 30px;
    width: 100%;
`;
const MailNav = () => {

    return (
        <MailNavContainer>
            <HeaderSearchContainer>
                <EmailConversationsHeader />
                <EmailConversationsSearch />
            </HeaderSearchContainer>

            <MailConversations />
        </MailNavContainer>
    )
}

const ConversationsContainer = styled.div`

    display: flex;
    flex-flow: column;
    overflow: auto;
    padding: 20px 0px;
    height: calc(100vh - 120px);

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #b30000;
        border-radius: 15px;
    }
`;

const MailChatContainer = styled.div`

    position: relative;
    padding: 30px 20px;
    width: 100%;

   
`;


const MessageArea = styled.div`

    display: flex;
    flex-flow: column;
    gap: 10px;
    width: 100%;
`;
const MailConversations = () => {

    const { conversations, setConversations, selectedConversationId, setSelectedConversationId } = useContext(MailContext)

    console.log(conversations);

    return (
        <ConversationsContainer>
           
                {conversations.map((conversation, index) => (
                    <NavConversation selected={conversation[0]['Message-Id'] === selectedConversationId} conversation={conversation}>
                        <strong> {conversation[0].Subject}</strong>
                        <div>{conversation[0].sender}</div>
                    </NavConversation>
                ))}
           
            
        </ConversationsContainer>
    )
}


const StyledConversationMessage = styled.div`

    width: fit-content;
    width: fit-content;
    display: flex;
    flex-flow: column;
    align-self: ${props => props.isSender ? 'flex-end': 'flex-start'};
`;

const Bubble = styled.div`

    position: relative;
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 10px;
    width: fit-content;
    padding-bottom: 20px;

    p{
        font-size: 12px;
        color: #000;
    }
    

`;

const TimeSent = styled.span`
    font-size: 10px;
    text-align: right;
    position: absolute;
    right: 10px;
    bottom: 5px;
`;


const ConversationMessage = (props) => {

    const { msg } = props
    const {user} = useContext(Context)
    const isSender = msg.sender === user.email
    console.log(msg);

    return (
        <StyledConversationMessage isSender = {isSender}>
            <Bubble>
                <p>{msg['stripped-text']}</p>
                {msg.attachments && <Attachments attachments={JSON.parse(msg.attachments)} />}
                <TimeSent>
                    {(new Date(msg.Date)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}
                </TimeSent>
            </Bubble>
        </StyledConversationMessage>
    )
}
const MailChat = () => {


    const { selectedConversationId, conversations, emails } = useContext(MailContext)

    useEffect(() => {

        // update any unread messages
        
    }, [])
    
    if (!selectedConversationId) return <div>No messages selected</div>

    const conversation = conversations.filter(convo => convo[0]['Message-Id'] === selectedConversationId)[0]




    return (
        <MailChatContainer>
             <MessageArea>
                {
                    conversation.map(msg => (
                       (!msg.isDraft &&  <ConversationMessage msg={msg} />)
                    ))
                }
            </MessageArea>
            <MessageComposer conversation = {conversation}/>
        </MailChatContainer>
    )
}


const NewMailComposerModal = styled.div`

    position: fixed;
    left: 0;
    top: 0;
    bottom : 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.85);

    display: flex;
    align-items: center;
    justify-content: center;
`;

const NewMailComposerContainer = styled.div`

    width: 400px;
    height: 600px;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
`;

const MessageContainer = styled.div`


    textarea{
        width: 100%;
        height: 300px;
    }
`;
const NewMailComposer = () => {


    const [recipient, setReceipient] = useState('')
    const [subject, setSubject] = useState('')
    const { user } = useContext(Context)
    const [messageBody, setMessageBody] = useState('')


    const handleSendMail = async () => {


        try {

            const response = await axiosInstance.post('/mail', {
                to: recipient,
                subject: subject,
                messageBody: messageBody,
                from: user.email,
                sender: user.email
            })

            console.log(response.data);

        } catch (error) {
            console.log(error);

        }
    }



    return (

        <NewMailComposerModal>
            <NewMailComposerContainer>
                <div>
                    <input type="text" placeholder='Receipients' value={recipient} onChange={(e) => setReceipient(e.target.value)} />
                </div>

                <div>
                    <input value={subject} type="text" placeholder='Subject' onChange={(e) => setSubject(e.target.value)} />
                </div>
                <MessageContainer>
                    <textarea value={messageBody} placeholder='Type your message' onChange={(e) => setMessageBody(e.target.value)}>

                    </textarea>
                </MessageContainer>
                <div>
                    <Button onClick={() => handleSendMail()}>Send</Button>
                </div>
            </NewMailComposerContainer>
        </NewMailComposerModal>
    )
}


const Mail = () => {


    const [emails, setEmails] = useState(null)
    const [selectedMailbox, setSelectedMailbox] = useState("Inbox");
    const [loading, setLoading] = useState(true)
    const [selectedConversationId, setSelectedConversationId] = useState(null)
    const [isNewMailCompose, setIsNewMailCompose] = useState(false)
    const [conversations, setConversations] = useState(null)

    const {user} = useContext(Context)

    useEffect(() => {

        const fetchMail = async () => {

            try {

                
                const response = await axiosInstance.get('/conversations', {
                    params: {
                        email: user.email
                    }
                })

                setConversations(response.data)
                setLoading(false)

            } catch (error) {

            }
        }

        fetchMail()

    }, [])



    if (loading) return <div>Loading...</div>
    return (
        <MailContext.Provider value={{ setIsNewMailCompose, selectedMailbox, setSelectedMailbox, conversations, setConversations, selectedConversationId, setSelectedConversationId }}>
            <Container>
                <MailNav />
                <MailChat />
                {isNewMailCompose && <NewMailComposer />}
            </Container>
            
        </MailContext.Provider>
    )
}

export default Mail
import React from 'react';
import styled from 'styled-components';
import { BrowserRouter, Routes, Route } from "react-router";
import Mail from './Mail';
const MainAreaContainer = styled.div`
    flex-grow: 1;
    
`;

const MainArea = (props) => {
    return (
        <MainAreaContainer>
            <Routes>
                <Route path="/" element={<Mail />} />
            </Routes>
        </MainAreaContainer>
    );
};

export default MainArea;
import React, { useRef, useEffect } from 'react'
import styled from 'styled-components';

const StyledIconButton = styled.button`

    cursor: pointer;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover{
        background-color:  rgba(134, 142, 153, .1);
    }
    svg{
        height: 17px;
        width: auto;
        fill: ${props => props.fill};   
    }

    /* transition: 125ms all linear; */

    transition: all 0.2s linear;
/* 
    &:active {
        transform: scale(0.98);
    } */
  
`;
const IconButton = (props) => {

    return (
        <StyledIconButton fill = {props.fill || '#000'} onClick={props.onClick}>
            {props.children}
        </StyledIconButton>
    )
}
export default IconButton
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
const root = ReactDOM.createRoot(document.getElementById('root'));


async function fetchData() {
	try {

		const response = await axios.get('https://api.personally.cloud/auth', {
			withCredentials: true
		});
		
		const {authToken, user} = response.data
		
		
		localStorage.setItem('auth-token', JSON.stringify(authToken))
		
		if(response.status === 200){ // this needs to be more robust and can't be the only check

			root.render(
				<App user={user}/>
			);
		}
		
		
	} catch (error) {

		root.render(
			<React.StrictMode>
				404 Not found
			</React.StrictMode>
		);
	}
}


fetchData();
reportWebVitals();

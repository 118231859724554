import styled from 'styled-components'
import React from 'react'
import EmailConversationsSettingButton from './EmailConversationsSettingButton'
import EmailConversationsComposeButton from './EmailConversationsComposeButton'

const EmailConversationsHeaderContainer = styled.div`

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;

    h3{
        font-weight: 500;
    }
`;
const ButtonsContainer = styled.div`

    display: flex;
    align-items: center;
`; 
const EmailConversationsHeader = () => {

    return (
        <EmailConversationsHeaderContainer>
            <span>Conversations</span>
            <ButtonsContainer>
                <EmailConversationsSettingButton />
                <EmailConversationsComposeButton />
            </ButtonsContainer>
            
        </EmailConversationsHeaderContainer>
    )
}

export default EmailConversationsHeader
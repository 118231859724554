import React, { useContext } from 'react';
import styled from 'styled-components';
import UserAvatar from "./UserAvatar";

import { Context } from '../App';

const UserContainer = styled.button`
    
    display: flex;
    align-items: center;
    gap: 10px;
    border: 0;
    background: transparent;
`;

const UserLabel = styled.div`
    display: flex;
    flex-flow: column;
    align-items: flex-start;
`;
const User = (props) => {

    const {user} = useContext(Context)

    const {firstname, lastname} = user
    
    return (
        <UserContainer>
            <UserAvatar size={40}/>
            <UserLabel>
                <span>{`${firstname} ${lastname}`}</span>
                <span>{window.location.host}</span>
            </UserLabel>
        </UserContainer>
    );
};

export default User;
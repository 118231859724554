import React, {useRef} from 'react'
import styled from 'styled-components'



const EmailConversationsSearchContainer = styled.div`

    display: flex;
    padding: 0px 15px;
    height: 50px;
    align-items: center;
    border-radius: 44px;
    gap: 15px;
    background-color: rgba(134, 142, 153, .1);
    cursor: text;

    &:hover{
        background-color: rgb(122 125 130 / 10%);
    }
`;


const EmailConversationsSearch = () => {

    const inputRef = useRef(null)

    return (
        <EmailConversationsSearchContainer onClick = {() => {inputRef.current.focus()}}>
            <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4872 12.6231L10.1887 9.19263C11.0371 8.18607 11.5021 6.91187 11.5015 5.59547C11.5015 2.51018 8.99132 0 5.90602 0C2.82073 0 0.310547 2.51018 0.310547 5.59547C0.310547 8.68077 2.82073 11.1909 5.90602 11.1909C7.06428 11.1909 8.16805 10.8416 9.11174 10.1784L12.4352 13.635C12.5741 13.7792 12.761 13.8588 12.9612 13.8588C13.1507 13.8588 13.3305 13.7865 13.467 13.6551C13.6063 13.5208 13.6866 13.3368 13.6904 13.1433C13.6942 12.9499 13.6211 12.7628 13.4872 12.6231ZM5.90602 1.45969C8.18654 1.45969 10.0418 3.31495 10.0418 5.59547C10.0418 7.87599 8.18654 9.73126 5.90602 9.73126C3.6255 9.73126 1.77024 7.87599 1.77024 5.59547C1.77024 3.31495 3.6255 1.45969 5.90602 1.45969Z" fill="#9A9A9A" />
            </svg>
            <input ref={inputRef} type="text" placeholder='Search Conversations' />
        </EmailConversationsSearchContainer>
    )
}

export default EmailConversationsSearch
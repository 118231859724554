import styled from 'styled-components';

const padding = '10px 20px'
const borderRadius = '8px'
const fontSize = '15px'


// const sharedStyles = `
//
//   padding: 10px 20px;
//   border-radius: 5px;
//   font-size: 15px;
//   font-weight: 500;
//   outline: none;
//
// `;

const Button = styled.button`
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.buttonTextColor};
  border: none;
  padding: ${padding};
  border-radius: ${borderRadius};
  cursor: pointer;
  font-size: ${fontSize};

  &:hover {
    background-color: ${(props) => props.theme.secondaryColor};
  }
`;

const Input = styled.input`
    
    background-color: rgb(239, 243, 244);
    border: none;
    padding: ${padding};
    border-radius: ${borderRadius};
    
    &:focus {
        
        outline: none;
    }
`
export { Button, Input};
import React, { useState, createContext, useEffect } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Sidebar from "./components/Sidebar";
import { light, dark } from './themes'
import { Button, Input } from './ui.js'
import MainArea from "./components/MainArea";
import axios from 'axios'
import userFlowComponents from './user-flows'
import { BrowserRouter } from "react-router";

const GlobalStyle = createGlobalStyle`
    
    body {
        background-color: ${(props) => props.theme.background};
        color: ${(props) => props.theme.textColor};
        font-family: 'Inter', sans-serif;
        margin: 0;
        padding: 0;
        user-select: none;
        
    }
    *{
        box-sizing: border-box;    
        font-family: 'Inter', sans-serif;
        padding: 0;
        margin: 0;
        border: 0;
        outline: none;
        background-color: transparent;
        list-style: none;
    }
   
`;

const Main = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    
`;

export const Context = createContext();

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL

function App(props) {

    const [isLoadshedding, setIsLoadshedding] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userFlows, setUserFlows] = useState(null);
    const [user, setUser] = useState(props.user);

    useEffect(() => {


        const fetchUserFlows = async () => {
            const authToken = JSON.parse(localStorage.getItem('auth-token'))
            const { userId } = authToken

            try {
                const response = await axios.get(`${apiServerUrl}/user-flows`, {
                    params: {
                        userId: userId
                    }
                })

                setIsLoading(false)
                setUserFlows(response.data)

            } catch (error) {
                console.log(error);

            }
        }

        fetchUserFlows()

    }, [])

    if (isLoading) return <div>loading...</div>

    let UserFlow = null

    if (userFlows && userFlows.length > 0) {
        const userFlowName = userFlows[0].name
        UserFlow = userFlowComponents[userFlowName].component;
    }

    return (
        <Context.Provider value={{ user, setUser }}>
            <BrowserRouter>



                {UserFlow && <UserFlow />}
                <ThemeProvider theme={!isLoadshedding ? light : dark}>
                    <GlobalStyle />

                    <Main>
                        {/* <Sidebar /> */}
                        <MainArea />
                        {/*<Button>Button</Button>*/}
                        {/*<Input type="text"/>*/}
                    </Main>
                    {/*<div onClick={() => {setIsLoadshedding(!isLoadshedding)}} style={{position: "fixed", right: "50px", top: "50px"}}>{isLoadshedding ? 'Let there be light' : 'Loadshedding mode'}</div>*/}
                </ThemeProvider>

            </BrowserRouter>
        </Context.Provider>
    );
}

export default App;

import React from 'react'
import styled from 'styled-components'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';



const Container = styled.div`

    position: relative;
    height: 16px;
    width: 16px;
     svg{
        width: 100%;
        height: 100%;
        transform: rotate(-90deg);
     }
     span{
        position: absolute;
        font-size: 6px;
        left: 50%;
        transform: translate(-49%, -54%);
        top: 50%;
        font-weight: 600;
     }
`;

const Circle = styled.circle`
    stroke-width: 3px;
    stroke: #edf2f7;
    color: #edf2f7;
`;

const Loader = styled.circle`

    stroke: #0C8CE9;
    color: #0C8CE9;
    stroke-width: 3px;
    stroke-dasharray: 100px;
    stroke-dashoffset: ${props => (100 - props.progress)}px;
    stroke-linecap: round;


`;
const AttachmentProgressLoader = (props) => {

    const {progress} = props
    return (
        <Container>
            <svg className="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                <Circle cx="18" cy="18" r="16" fill="none" className="stroke-current text-gray-200" strokeWidth="2"></Circle>
                <Loader  progress={progress} cx="18" cy="18" r="16" fill="none" className="stroke-current text-blue-600" strokeWidth="2" strokeDasharray="100" strokeDashoffset="65" strokeLinecap="round"></Loader>
            </svg>
            <span>{progress}</span>
        </Container>
    )
}

export default AttachmentProgressLoader
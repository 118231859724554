import React, {useState, useRef, useContext } from 'react'
import styled from 'styled-components'
import man from '../img/man.png'; // stuff should be coming from Bezos
import pen from '../img/pen.png'; // stuff should be coming from Bezos
import axios from 'axios'
import {Context} from '../App'


const Wrapper = styled.div`

    position: fixed;
    height: 100vh;
    width: 100vw;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-color: #fff;

`;
const Container = styled.div`
   
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #ccc;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
`;

const UserAvatarContainer = styled.div`

    position: relative;
    height: 128px;
    width: 128px;
    border-radius: 50%;
    border: 1px solid #ccc;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    margin-bottom: 20px;
    cursor: pointer;

    input{
        display: none;
    }
`;

const AvatarImg = styled.img`
    
    height: 100%;
    width: auto;

`;

const Pen = styled.button`

    position: absolute;
    background-color: transparent;
    border: none;
    height: 40px;
    img{
        height: 100%;
        width: auto;
    }
    cursor: pointer;
`;
export default () => {


    const fileInputRef = useRef(null);
    const [image, setImage ] = useState(man)
    const [file, setFile ] = useState(null)
    const {user, setUser} = useContext(Context)
    
    const handleUploadClick = () => {

        fileInputRef.current.click();
    }
    
    const handleFileChange = (event) => {
        const file = event.target.files[0]; // Get the selected file
        if (file) {
            
            setFile(file)
            const previewUrl = URL.createObjectURL(file);
            setImage(previewUrl)
            
        }
    };

    const uploadImage = async () => {

        const formData = new FormData();
        formData.append('file', file); 

       try {
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER_URL}/upload-user-profile-picture`,  formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true,
            })
            
            setUser(response.data)
         
            
       } catch (error) {

        console.log(error);
        
       }
        
    
    }
    return (
        <Wrapper> 
            <Container>
                <h3>Upload profile picture</h3>
                <UserAvatarContainer onClick={handleUploadClick}>
                    <AvatarImg draggable="false" src={image} alt="" />
                    <Pen>
                        <img src={pen} alt="" />
                    </Pen>
                </UserAvatarContainer>
                <input style={{display: 'none'}} ref={fileInputRef} type="file"  onChange={handleFileChange}/>
                <button onClick = {() => uploadImage()}>Save</button>
            </Container>
        </Wrapper>
    )
}

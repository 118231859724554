import React, { useRef, useEffect, useState, useContext, createRef, useCallback } from 'react'
import styled from 'styled-components';
import { Button } from '../ui'
import IconButton from './IconButton';
import axiosInstance from '../api/axiosInstance';
import { Context } from '../App';
import axios from 'axios'
import AttachmentProgressLoader from './AttachmentProgressLoader';
import mimeTypesIcon from './mimeTypesIcon';
const Wrapper = styled.div`
    
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f9f9f9;
    padding: 20px 0;
`;

const Container = styled.div`

    display: flex;
    align-items: center;
    /* background-color: #f1f1f1; */
    border-radius: 20px;
    width: 100%;
`;

const MessageInputContainer = styled.div`

    flex-grow: 1;
    padding: 13px;
    input{
        width: 100%;
    }
`

const MessageInput = (props) => {

    const inputRef = useRef(null);

    const { value, handleMessageInputChange } = props

    useEffect(() => {

        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <MessageInputContainer>
            <input onChange={(e) => handleMessageInputChange(e)} ref={inputRef} placeholder="Type your message" type="text" value={value} />
        </MessageInputContainer>
    )
}


const ComposerActionsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;



const SendButton = (props) => {

    return (
        <IconButton onClick={props.onClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.101 10.5622L2.753 1.12319C2.56707 1.03258 2.36114 0.990775 2.15459 1.0017C1.94805 1.01263 1.74768 1.07593 1.57235 1.18565C1.39702 1.29538 1.25248 1.4479 1.15236 1.62889C1.05223 1.80987 0.999799 2.01335 1 2.22019V2.25519C1.0001 2.4187 1.02025 2.58158 1.06 2.74019L2.916 10.1642C2.94088 10.2631 2.9954 10.352 3.07226 10.419C3.14912 10.4861 3.24464 10.528 3.346 10.5392L11.503 11.4462C11.6387 11.4625 11.7638 11.528 11.8545 11.6303C11.9452 11.7325 11.9952 11.8645 11.9952 12.0012C11.9952 12.1379 11.9452 12.2698 11.8545 12.3721C11.7638 12.4744 11.6387 12.5399 11.503 12.5562L3.346 13.4632C3.24464 13.4744 3.14912 13.5163 3.07226 13.5833C2.9954 13.6504 2.94088 13.7393 2.916 13.8382L1.06 21.2612C1.02025 21.4198 1.0001 21.5827 1 21.7462V21.7812C0.999969 21.9879 1.05252 22.1913 1.15272 22.3721C1.25292 22.553 1.39747 22.7054 1.57277 22.815C1.74808 22.9246 1.94838 22.9878 2.15484 22.9987C2.36131 23.0096 2.56714 22.9678 2.753 22.8772L22.1 13.4382C22.3694 13.3067 22.5965 13.1022 22.7554 12.848C22.9142 12.5937 22.9985 12.3 22.9985 12.0002C22.9985 11.7004 22.9142 11.4066 22.7554 11.1524C22.5965 10.8981 22.3704 10.6936 22.101 10.5622Z" />
            </svg>
        </IconButton>
    )
}
const AttachButton = (props) => {

    return (
        <IconButton onClick={props.onClick} >
            <svg width="14" height="22" viewBox="0 0 14 22" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 21.7709C5.35942 21.7695 3.78645 21.1171 2.62639 19.957C1.46632 18.797 0.813956 17.224 0.8125 15.5834V4.58342C0.8125 2.18175 2.765 0.229248 5.16667 0.229248C7.56833 0.229248 9.52083 2.18175 9.52083 4.58342V14.6667C9.52083 16.0564 8.38967 17.1876 7 17.1876C5.61033 17.1876 4.47917 16.0564 4.47917 14.6667V6.41675C4.47917 6.23441 4.5516 6.05954 4.68053 5.93061C4.80946 5.80168 4.98433 5.72925 5.16667 5.72925C5.349 5.72925 5.52387 5.80168 5.6528 5.93061C5.78173 6.05954 5.85417 6.23441 5.85417 6.41675V14.6667C5.87073 14.9594 5.99867 15.2347 6.21174 15.436C6.4248 15.6374 6.70685 15.7496 7 15.7496C7.29315 15.7496 7.5752 15.6374 7.78826 15.436C8.00133 15.2347 8.12927 14.9594 8.14583 14.6667V4.58342C8.14583 2.94075 6.80933 1.60425 5.16667 1.60425C3.524 1.60425 2.1875 2.94075 2.1875 4.58342V15.5834C2.1875 18.2362 4.34717 20.3959 7 20.3959C9.65283 20.3959 11.8125 18.2362 11.8125 15.5834V6.41675C11.8125 6.23441 11.8849 6.05954 12.0139 5.93061C12.1428 5.80168 12.3177 5.72925 12.5 5.72925C12.6823 5.72925 12.8572 5.80168 12.9861 5.93061C13.1151 6.05954 13.1875 6.23441 13.1875 6.41675V15.5834C13.186 17.224 12.5337 18.797 11.3736 19.957C10.2135 21.1171 8.64058 21.7695 7 21.7709Z" />
            </svg>
        </IconButton>
    )
}
const ComposerActions = () => {

    return (
        <ComposerActionsContainer>
            <AttachButton />

            <SendButton />
        </ComposerActionsContainer>
    )
}


const AttachmentsContainer = styled.div`

    padding: 0px 20px;
    display: flex;
    align-items:center;
    gap: 10px;
`;


const AttachmentContainer = styled.div`

    position: relative;
    font-size: 10px;
    display: flex;
    height: 30px;
    align-items: center;
    gap: 5px;
    padding: 0 15px;
    border-radius: 50px;
    width: 150px;
    background-color: #e4e4e4;

`

const MimeTypeIconContainer = styled.div`
    svg{
        height: 16px;
        width: auto;
    }
`;

const ProgressIndicator = styled.div`
       margin-left: auto;
`;

const Filename = styled.span`

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
`;

const Attachment = (props) => {

    const { attachment } = props
    const [isAttached, setIsAttached] = useState(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {

        const uploadFile = async () => {

            try {

                let response = await axiosInstance.get('/signed-upload-url', {
                    params: {
                        fileType: attachment.type,
                        fileName: attachment.name
                    }
                })

                const presignedUrl = response.data.url

                response = await axios.put(presignedUrl, attachment, {
                    headers: {
                        'Content-Type': attachment.type
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentage = Math.round(progressEvent.progress * 100);
                        if (percentage >= 100) {
                            setIsAttached(1)
                        }
                        setProgress(percentage);


                    }
                });

                console.log(response);



            } catch (error) {
                console.log(error);

            }


        }

        uploadFile()

    }, [])

    return (
        <AttachmentContainer>
            <MimeTypeIconContainer>{mimeTypesIcon[attachment.type]}</MimeTypeIconContainer>
            <Filename>{attachment.name}</Filename>
            <ProgressIndicator>
                {!isAttached && <AttachmentProgressLoader progress={progress} />}
                {isAttached && <div>Delete</div>}
            </ProgressIndicator>
        </AttachmentContainer>
    )
}


const NewEmailAttachments = (props) => {

    const { attachments } = props
    return (
        <AttachmentsContainer>
            {
                attachments.map(attachment => <Attachment attachment={attachment} />)
            }
        </AttachmentsContainer>
    )
}



function useDebounce(callback, delay) {
    const timeoutRef = useRef(null);

    return useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);
}

const MessageComposer = (props) => {

    const { conversation } = props

    const lastMessage = conversation[conversation.length - 1]

    console.log(lastMessage);
    
    const [message, setMessage] = useState(lastMessage.isDraft ? lastMessage['stripped-text'] : '')
    const [draftId, setDraftId] = useState(lastMessage.isDraft ? lastMessage._id : null);

    const [attachments, setAttachments] = useState([])

    const { user } = useContext(Context)

    const fileInputRef = createRef(null)



    const saveDraft = async (content) => {
        
        if(draftId){
            
            try {
                const response = await axiosInstance.put('/email-drafts', {
                    'stripped-text': content,
                    draftId: draftId,
                })
                console.log(response);
                
            } catch (error) {
                
            }
        }
        else{
            try {
                const response = await axiosInstance.post('/email-drafts', {
                    'stripped-text': content,
                    'In-Reply-To': lastMessage['Message-Id'],
                    'References': `${lastMessage['References'] || ''} ${lastMessage['Message-Id']}`
                })

                setDraftId(response.data.draftId)
                
                console.log(response);
                
            } catch (error) {
                
            }
            
        }
        
    };


    const debouncedSave = useDebounce(saveDraft, 1000);
   
    const handleSend = async () => {


        const postData = {
            from: `"${user.firstname} ${user.lastname}" <${user.email}>`,
            to: lastMessage.sender,
            text: message,
            subject: conversation[0].Subject,
            sender: user.email,
            headers: {
                'In-Reply-To': lastMessage['Message-Id']
            }
        }

        if (lastMessage.References) {
            postData.headers.References = `${lastMessage.References} ${lastMessage['Message-Id']}`

        }
        else {
            postData.headers.References = lastMessage['Message-Id']
        }

        const response = await axiosInstance.post('/mail', postData)
        console.log(lastMessage);

    }


    const handleAttachClick = () => {

        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }
    const handleFileUpload = async (e) => {

        const file = e.target.files[0]

        const { type, name } = file

        let newAttachments = [...attachments, file]
        setAttachments(newAttachments)

        return



    }

    const handleMessageInputChange = (e) => {
        const { value } = e.target;
        setMessage(value);
        debouncedSave(value); // Save the draft after 300ms of inactivity
    };
    return (
        <Wrapper>
            {attachments.length > 0 && <NewEmailAttachments attachments={attachments} />}
            <Container>
                <AttachButton onClick={() => handleAttachClick()} />
                <input onChange={(e) => handleFileUpload(e)} type="file" ref={fileInputRef} style={{ display: 'none' }} />
                <MessageInput value={message} handleMessageInputChange={handleMessageInputChange} />
                <SendButton onClick={() => handleSend()} />

            </Container>

        </Wrapper>
    )
}

export default MessageComposer
import React from 'react';
import styled from 'styled-components';
import User from "./User";
import Navigation from './Navigation'

const SidebarContainer = styled.div`
   
    height: 100%;
    padding: 20px;
    border-right: 2px solid #ccc;
`;

const Sidebar = (props) => {
    return (
        <SidebarContainer>
            <User />
            <Navigation />
        </SidebarContainer>
    );
};

export default Sidebar;
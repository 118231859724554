const light = {
    background: '#ffffff',
    textColor: '#333',
    buttonTextColor: '#fff',
    primaryColor: '#000',
    secondaryColor: '#6c757d',
};

const dark = {
    background: '#333',
    textColor: '#fff',
    primaryColor: '#fff',
    buttonTextColor: '#000',
    secondaryColor: '#6c757d',
};

module.exports = {light, dark}
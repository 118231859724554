import React, { useContext } from 'react'
import styled from 'styled-components'
import { MailContext } from './Mail';


const NavConversationContainer = styled.div`

	position: relative;
	height: 60px;
	display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0 30px;
    background-color: ${props => props.selected ? 'rgba(134, 142, 153, .1)' : 'transparent'};
	border-top: ${props => props.selected ? '1px solid rgba(134, 142, 153, .1)' : 'none'};
	border-bottom: ${props => props.selected ? '1px solid rgba(134, 142, 153, .1)' : 'none'};
	border-right: ${props => props.selected ? '4px solid #0C8CE9' : 'none'};
	box-shadow: ${props => props.selected ? '0 -1px 0 0 rgba(134, 142, 153, .1), 0 1px 0 0 rgba(134, 142, 153, .1)' : 'none'};

	&:not(:last-child)::after {
		content: '';
    position: absolute;
    bottom: 0;
    left: 30px;
    right: 30px;
    height: 1px;
    background-color: rgba(134, 142, 153, .1);
  }
`;



const Sender = styled.span`

	font-size: 11px;
	color: ${props => props.hasUnread ? '#000' : '#737373'};

	display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
	
`;
const TimeSent = styled.span`

	font-weight: 600;
	font-size: 10px;
	color: ${props => props.hasUnread ? '#0C8CE9' : '#737373'};

`;

const Header = styled.div`

	display: flex;
	justify-content : space-between;
	align-items: baseline;
	width: 100%;
	margin-bottom: 6px;
`

const AttachmentIcon = () => {
	return(
		<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.97006 1.32269C8.59905 -0.0483188 6.36817 -0.0483188 4.99712 1.32269L0.734368 5.58538C-0.24485 6.56473 -0.244786 8.15823 0.73454 9.13751C1.22424 9.62721 1.86727 9.87205 2.51055 9.87198C3.15367 9.87192 3.797 9.62714 4.28663 9.13751L8.19401 5.23009C8.78165 4.64258 8.78171 3.68648 8.19407 3.09875C7.60643 2.51117 6.65029 2.51119 6.06277 3.09885L3.50752 5.65402C3.31134 5.85018 3.31134 6.16825 3.50747 6.36445C3.70369 6.56062 4.02171 6.56062 4.21792 6.36445L6.77322 3.80922C6.96907 3.61334 7.28779 3.61334 7.48365 3.80915C7.6795 4.00507 7.6795 4.32373 7.48358 4.5196L3.57625 8.42709C2.9886 9.01464 2.03255 9.01473 1.44495 8.42713C0.857367 7.83951 0.857302 6.88341 1.44482 6.29579L5.70755 2.03312C6.68687 1.05388 8.28035 1.05383 9.25968 2.03316C9.7341 2.50754 9.99539 3.1383 9.99535 3.8092C9.99535 4.48011 9.7341 5.11085 9.25962 5.58527L4.99701 9.84803C4.80084 10.0442 4.80084 10.3623 4.99706 10.5585C5.04365 10.6052 5.09902 10.6422 5.15998 10.6675C5.22093 10.6927 5.28628 10.7057 5.35226 10.7056C5.41824 10.7057 5.48359 10.6927 5.54455 10.6675C5.60551 10.6422 5.66088 10.6052 5.70748 10.5585L9.97002 6.29574C10.6342 5.63159 11 4.74854 11 3.80924C11.0001 2.86989 10.6343 1.98684 9.97006 1.32269Z" fill="black"/>
		</svg>
	)
}

const Subject = styled.div`

	font-size: 13px;
	font-weight: 600;
	color: ${props => props.hasUnread ? '#000' : '#737373'};

`;

const Details = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;

const NumUnread = styled.div`

	display: flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background-color: #0C8CE9;
	color: #fff;
	font-size: 8px;
	font-weight: 500;
	margin-left: auto;

`;

const DraftIndicator = styled.div`

	font-size: 12px;
	color: red;
`;
const NavConversation = (props) => {

	const { selectedConversationId, setSelectedConversationId } = useContext(MailContext)

	const { conversation } = props
	const numUnread = conversation.filter(message => message.isRead === 0).length;
	const conversationId = conversation[0]['Message-Id']
	const selected = (conversationId === selectedConversationId)
	const hasUnread = numUnread > 0 && !selected
	
	
	console.log(conversation);
	const lastMessage = conversation[conversation.length - 1]
	return (
		<NavConversationContainer selected = {selected} onClick={() => setSelectedConversationId(conversationId)}>
			<Header>
				<Subject hasUnread = {hasUnread}>
					{conversation[0].Subject}
				</Subject>
				<TimeSent  hasUnread = {hasUnread}>
					18:00
				</TimeSent>
			</Header>
			<Details>
				
				{}
				<Sender hasUnread = {hasUnread}>
					{lastMessage.isDraft ? <DraftIndicator>Draft</DraftIndicator> : <span>{conversation[0].sender}:</span>}
					<span>{lastMessage['stripped-text']}</span>
				</Sender>
				{
					lastMessage.attachments && <AttachmentIcon />
				}
				{hasUnread && <NumUnread>{numUnread}</NumUnread>}
			</Details>
		</NavConversationContainer>
	)
}

export default NavConversation
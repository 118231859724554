import React from 'react'
import pdf from '../img/pdf.png'
import audio from '../img/audio-file.png'

import styled from 'styled-components';


const mimeTypes = [
    {
        name: "application/pdf",
        icon: pdf
    },
    {
        name: "audio/mpeg",
        icon: audio
    }
]

const StyledAttachment = styled.div`

    cursor: pointer;
    width: 140px;
    padding: 10px 15px;
    border-radius: 20px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: #000000;
    img{
        height: 18px;
        width: auto;
    }
    span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

`;

const AttachmentsContainer = styled.div`
    display: flex;
    margin-top: 15px;
    gap: 10px;
`;
const Attachments = (props) => {

    const {attachments} = props

    console.log(attachments);
    
    return (
        <AttachmentsContainer>
            {attachments.map(attachment => {
                return(
                    <StyledAttachment>
                        <img src={mimeTypes.filter(type => type.name === attachment['content-type'])[0].icon} alt="" />
                       <span>{attachment.name}</span>
                    </StyledAttachment>
                )
            })}
        </AttachmentsContainer>
    )
}

export default Attachments